exports.components = {
  "component---src-routes-403-js": () => import("./../../../src/routes/403.js" /* webpackChunkName: "component---src-routes-403-js" */),
  "component---src-routes-404-js": () => import("./../../../src/routes/404.js" /* webpackChunkName: "component---src-routes-404-js" */),
  "component---src-routes-hexgamebox-hex-game-box-privacy-policy-page-js": () => import("./../../../src/routes/hexgamebox/HexGameBoxPrivacyPolicyPage.js" /* webpackChunkName: "component---src-routes-hexgamebox-hex-game-box-privacy-policy-page-js" */),
  "component---src-routes-hexpipes-hex-pipes-page-js": () => import("./../../../src/routes/hexpipes/HexPipesPage.js" /* webpackChunkName: "component---src-routes-hexpipes-hex-pipes-page-js" */),
  "component---src-routes-hexpipes-hex-pipes-privacy-policy-page-js": () => import("./../../../src/routes/hexpipes/HexPipesPrivacyPolicyPage.js" /* webpackChunkName: "component---src-routes-hexpipes-hex-pipes-privacy-policy-page-js" */),
  "component---src-routes-hexsaper-hex-saper-page-js": () => import("./../../../src/routes/hexsaper/HexSaperPage.js" /* webpackChunkName: "component---src-routes-hexsaper-hex-saper-page-js" */),
  "component---src-routes-hexsaper-hex-saper-privacy-policy-page-js": () => import("./../../../src/routes/hexsaper/HexSaperPrivacyPolicyPage.js" /* webpackChunkName: "component---src-routes-hexsaper-hex-saper-privacy-policy-page-js" */),
  "component---src-routes-hexsudoku-hex-sudoku-page-js": () => import("./../../../src/routes/hexsudoku/HexSudokuPage.js" /* webpackChunkName: "component---src-routes-hexsudoku-hex-sudoku-page-js" */),
  "component---src-routes-hexsudoku-hex-sudoku-privacy-policy-page-js": () => import("./../../../src/routes/hexsudoku/HexSudokuPrivacyPolicyPage.js" /* webpackChunkName: "component---src-routes-hexsudoku-hex-sudoku-privacy-policy-page-js" */),
  "component---src-routes-home-page-js": () => import("./../../../src/routes/HomePage.js" /* webpackChunkName: "component---src-routes-home-page-js" */),
  "component---src-routes-other-click-and-play-click-and-play-privacy-policy-page-js": () => import("./../../../src/routes/other/click-and-play/ClickAndPlayPrivacyPolicyPage.js" /* webpackChunkName: "component---src-routes-other-click-and-play-click-and-play-privacy-policy-page-js" */),
  "component---src-routes-other-floaty-fish-floaty-fish-page-js": () => import("./../../../src/routes/other/floaty-fish/FloatyFishPage.js" /* webpackChunkName: "component---src-routes-other-floaty-fish-floaty-fish-page-js" */),
  "component---src-routes-other-sudoscanner-sudo-scanner-privacy-policy-page-js": () => import("./../../../src/routes/other/sudoscanner/SudoScannerPrivacyPolicyPage.js" /* webpackChunkName: "component---src-routes-other-sudoscanner-sudo-scanner-privacy-policy-page-js" */)
}

